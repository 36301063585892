.description_component {
    width: 100%;
    height: 62.8rem;
    display: flex;
    justify-content: center;
    align-items: center;

}

.description_component p {
    width: fit-content;
    max-width: 92.7rem;
    height: 37.7rem;
    font-family: "Trip Sans";
    font-size: 3rem;
    color: white;
    font-weight: bold;
    margin: 0;
    padding: 0;
    text-align: center;
}

.description_component_background {
    background-image: url(../../assets/descriptionBackgroundMobile.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

}

@media(max-width: 768px) {
    .description_component {
        display: block;
        height: fit-content;
    }

    .description_component p {
        max-width: calc(100vw - 5rem);
        padding: 3rem 5rem;
        margin: auto;
        font-size: 2rem;
        height: fit-content;
    }
}

