@font-face {
    font-family: 'Trip Sans Ultra';
    src: url('../assets/fonts/TripSans/TripSans-Ultra.eot');
    src: local('Trip Sans Ultra'), local('TripSans-Ultra'),
        url('../assets/fonts/TripSans/TripSans-Ultra.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/TripSans/TripSans-Ultra.woff2') format('woff2'),
        url('../assets/fonts/TripSans/TripSans-Ultra.woff') format('woff'),
        url('../assets/fonts/TripSans/TripSans-Ultra.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Trip Sans';
    src: url('../assets/fonts/TripSans/TripSans-Bold.eot');
    src: local('Trip Sans Bold'), local('TripSans-Bold'),
        url('../assets/fonts/TripSans/TripSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/TripSans/TripSans-Bold.woff2') format('woff2'),
        url('../assets/fonts/TripSans/TripSans-Bold.woff') format('woff'),
        url('../assets/fonts/TripSans/TripSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Trip Sans';
    src: url('../assets/fonts/TripSans/TripSans-Medium.eot');
    src: local('Trip Sans Medium'), local('TripSans-Medium'),
        url('../assets/fonts/TripSans/TripSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/TripSans/TripSans-Medium.woff2') format('woff2'),
        url('../assets/fonts/TripSans/TripSans-Medium.woff') format('woff'),
        url('../assets/fonts/TripSans/TripSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Trip Sans Mono';
    src: url('../assets/fonts/TripSans/TripSansMono-Regular.eot');
    src: local('Trip Sans Mono Regular'), local('TripSansMono-Regular'),
        url('../assets/fonts/TripSans/TripSansMono-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/TripSans/TripSansMono-Regular.woff2') format('woff2'),
        url('../assets/fonts/TripSans/TripSansMono-Regular.woff') format('woff'),
        url('../assets/fonts/TripSans/TripSansMono-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Trip Sans';
    src: url('../assets/fonts/TripSans/TripSans-Regular.eot');
    src: local('Trip Sans Regular'), local('TripSans-Regular'),
        url('../assets/fonts/TripSans/TripSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/TripSans/TripSans-Regular.woff2') format('woff2'),
        url('../assets/fonts/TripSans/TripSans-Regular.woff') format('woff'),
        url('../assets/fonts/TripSans/TripSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}