.frame1_container {
    position: relative;
    width: 100%;
    height: 100dvh;
    z-index: 2;
}

.frame1_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 0px;
    margin-top: 15.2rem;
    position: absolute;
}

.frame1_logo_img {
    color: white;
    font-size: 9.6rem;
    margin-left: 10.3rem;
}

.frame1_social_media_links {
    margin-right: 7.5rem;
    display: flex;
    gap: 2.9rem;
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;

}



@media(min-width: 768px) {
    .frame1_container {
        pointer-events: visiblePainted;
    }
}

@media(max-width: 769px) {
    .frame1_wrapper {
        flex-direction: column;
        height: 100%;
        align-items: center;
        padding: 0;
        margin: 0;
        width: 0px;
        margin: auto;
        inset: 0;
    }

    .frame1_logo_img {
        font-size: 4rem;
        height: 4.8rem;
        margin: 0;
        margin-top: 8.4rem;
    }

    .frame1_social_media_links {
        margin: 0;
        margin-bottom: 12.6rem;
    }

    .frame1_social_media_links img {
        width: 2rem;
        height: 2rem;
    }
}