#logo3d_component {
    position: relative;
    width: 100vw;
    height: 100dvh;
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;

}

.logo3d_component__element {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}

/* Mobile Phones */
@media(max-width: 768px) {
    #logo3d_component {
        height: 100dvh;
        width: 100vw;
    }

    .logo3d_component__element {
        pointer-events: none;
    }
}