.slider_component {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: black;
    list-style: none;
}

.slide {
    position: absolute;
    inset: 0;
    opacity: 0;
    transition: 1s opacity ease-in-out;
    transition-delay: 1s;
    
}

.slide > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.slide[data-active=true] {
    z-index: 1;
    opacity: 1;
    transition-delay: 0ms;
    transition: 2.5s;
}

.slider_component_description {
    display: flex;
    flex-direction: column;
    align-items: start;
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 8.1rem;
    color: white;
    cursor: pointer;
}

.slider_component_description_background {
    position: absolute;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 8.1rem;
    background-color: black;
    opacity: 25%;
}

.slider_component_description_content {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: 1rem;
    margin-left: 1.4rem;
    font-family: Trip Sans;
}

.slider_component_description_content > * {
    margin: 0;
}

.slider_component_description_content > h5 {
    font-size: 2.2rem;
}
.slider_component_description_content > p {
    font-size: 1.9rem;
}


/* Mobile Phones */
@media (max-width: 768px) {
    .slide > img {
        object-fit: contain;
    }
}
