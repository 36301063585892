.player_component {
    position: absolute;
    margin: auto;
    inset: 0;
    background-color: rgb(0, 0, 0);
    min-width: 60rem;
    max-width: 100vw;
    height: 58.4rem;
} 

.player_component_background {
    width: 100vw;
    height: 100%;
    background-color: black;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
}

@media (max-width: 60rem) {
    .player_component {
        position: absolute;
        background-color: rgb(0, 0, 0);
        min-width: 0rem;
        max-width: 100vw;
        height: 94rem;
        top: 0;
        margin: 0;
        inset: 0;
        margin-bottom: auto;
    } 
}

