.footer_component {
    width: 100%;
    height: 100%;
    background-color: black;
    color: white;
    font-family: "Trip Sans";
    display: flex;
    align-items: center;
    flex-direction: column;
}

.footer_component > * {
    margin: 0;
}

.footer_component_title {
    color: #FF0000;
    font-weight: bold;
    font-size: 5rem;
    margin-top: 6.5rem;
}

.footer_component_subtitle {
    color: #FF0000;
    font-weight: bold;
    font-size: 5rem;
    margin-top: 3.2rem;
}

.footer_component_trusted_by {
    max-height: 50%;
}

.footer_component_email {
    font-weight: 400;
    color: white;
    font-size: 2rem;
    margin-top: .9rem;
}

.footer_component_copyright {
    position: absolute;
    bottom: 5.2rem;
    font-size: 1.2rem;
    margin-top: auto;
 
}

/* Mobile Phones */
@media(max-width: 768px) {
    .footer_component_title, .footer_component_subtitle {
        font-size: 2.5rem;
    }

    .footer_component_trusted_by {
        width: 100vw;
    }
}

